@font-face {
  font-family: 'Fira Sans';
  src: url('./fonts/FiraSans-Regular.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Fira Sans';
  font-style: italic;
  src: url('./fonts/FiraSans-Italic.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Fira Sans';
  font-weight: bold;
  src: url('./fonts/FiraSans-Bold.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Fira Sans';
  font-style: italic;
  font-weight: bold;
  src: url('./fonts/FiraSans-BoldItalic.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Fira Mono';
  src: url('./fonts/FiraMono-Regular.ttf') format('truetype');
  font-display: swap;
}

html,
body,
#root {
  height: -webkit-fill-available;
}

input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  -webkit-appearance: none;
}
